import request from '@/assets/js/request';

/**
 * @description 应用、配置管理相关api
 */

/*应用*/
export function getAppList(data) {
  return request({
    url: '/v1/apps/list',
    method: 'post',
    data,
  });
}

export function getAppInfo(data) {
  return request({
    url: '/v1/apps/info',
    method: 'post',
    data,
  });
}

export function appAdd(data) {
  return request({
    url: '/v1/apps/add',
    method: 'post',
    data,
  });
}

export function appEdit(data) {
  return request({
    url: '/v1/apps/edit',
    method: 'post',
    data,
  });
}

/*配置管理*/
export function getThirdsList(data) {
  return request({
    url: '/v1/thirds/list',
    method: 'post',
    data,
  });
}

export function thirdEdit(data) {
  return request({
    url: '/v1/thirds/edit',
    method: 'post',
    data,
  });
}

export function thirdAdd(data) {
  return request({
    url: '/v1/thirds/add',
    method: 'post',
    data,
  });
}
export function thirdDel(data) {
  return request({
    url: '/v1/thirds/del',
    method: 'post',
    data,
  });
}

// 绑定
export function appThirdAdd(data) {
  return request({
    url: '/v1/app_third/add',
    method: 'post',
    data,
  });
}
// 换绑
export function appThirdEdit(data) {
  return request({
    url: '/v1/app_third/edit',
    method: 'post',
    data,
  });
}

// 已绑列表
export function appThirdList(data) {
  return request({
    url: '/v1/app_third/list',
    method: 'post',
    data,
  });
}

// 删除绑定
export function delAppThird(data) {
  return request({
    url: '/v1/app_third/del',
    method: 'post',
    data,
  });
}

// 用户数据
export function userDay(data) {
  return request({
    url: '/v1/data/userDay',
    method: 'post',
    data,
  });
}

// 充值数据
export function orderDay(data) {
  return request({
    url: '/v1/data/orderDay',
    method: 'post',
    data,
  });
}

// 来源列表
export function getFromList(data) {
  return request({
    url: '/v1/thirds/from_list',
    method: 'post',
    data,
  });
}

