<template>
  <div class="nodata">暂无数据</div>
</template>

<script>
export default {
  name: 'nodata',
};
</script>

<style lang="scss" scoped>
.nodata {
  background: url(~@/assets/images/nodata.png) no-repeat;
  background-size: 100%;
  width: 450px;
  height: 450px;
  padding-top: 400px;
  margin: 0 auto;
  text-align: center;
  color: #cdcdcd;
}
</style>
