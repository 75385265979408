<template>
  <div
    class="application"
    v-loading="pageLoading">
    <div
      class="add"
      @click="handelAdd">
      新增应用
    </div>
    <div
      class="lists"
      v-if="lists.length > 0">
      <div
        class="list-item"
        v-for="(item, index) in lists"
        :key="index">
        <div class="icon-edit">
          <i
            class="el-icon-edit-outline"
            @click="handelEdit(item)"></i>
        </div>
        <div class="list-item-one">
          <span class="primary-color name">{{ item.name }}</span>
          <span>包名： {{ item.package_name }}</span>
          <span
            >账号模式：{{
              mergeOptions.find((items) => item.is_merge_uid === items.value)
                .name
            }}
          </span>
        </div>
        <div class="flex">
          <div class="flex-left summary">
            <div class="flex">
              <div class="summary-item">
                <div class="bolder">新增用户（过去7天）</div>
                <div class="bolder">
                  {{ item.last7data.user }}

                  <span
                    :class="
                      item.last7data.user_yoy > 0
                        ? 'up'
                        : item.last7data.user_yoy === 0
                        ? ''
                        : 'down'
                    "
                    >{{ item.last7data.user_yoy }}%
                    <template v-if="item.last7data.user_yoy !== 0">
                      <i
                        class="el-icon-top"
                        v-if="item.last7data.user_yoy > 0"></i>
                      <i
                        class="el-icon-bottom"
                        v-else></i>
                    </template>
                  </span>
                </div>
              </div>
              <div class="summary-item">
                <div class="bolder">充值金额（过去7天）</div>
                <div class="bolder">
                  {{ item.last7data.amount }}
                  <span
                    :class="
                      item.last7data.amount_yoy > 0
                        ? 'up'
                        : item.last7data.amount_yoy === 0
                        ? ''
                        : 'down'
                    "
                    >{{ item.last7data.amount_yoy }}%
                    <template v-if="item.last7data.amount_yoy !== 0">
                      <i
                        class="el-icon-top"
                        v-if="item.last7data.amount_yoy > 0"></i>
                      <i
                        class="el-icon-bottom"
                        v-else></i>
                    </template>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-right">
            <div style="margin-top: 15px">
              <span class="bolder">adjust模式：</span>
              {{ item.adjust_config.mode === '0' ? '沙盒' : '生产' }}
            </div>
            <div>
              <span class="bolder">adjust状态：</span>
              {{ item.adjust_config.status === '0' ? '禁用' : '启用' }}
            </div>
          </div>
        </div>
        <div class="flex">
          <div>
            <span class="bolder">id生成倾向：</span
            >{{ item.generate_type === 1 ? '系统' : '传值' }}
          </div>
          <div>
            <span class="bolder">id生成规则： </span>
            {{
              item.generate_rule === 0
                ? '暂无配置'
                : item.generate_rule === 1
                ? '系统固定'
                : '系统随机'
            }}
          </div>
        </div>
        <div class="flex">
          <div>
            <span class="bolder">支付回调地址：</span>{{ item.pay_notify_url }}
          </div>
          <div>
            <span class="bolder">配置列表： </span
            ><span
              class="bind-btn"
              @click="handelBindShow(item)"
              >管理</span
            >
          </div>
        </div>
        <div class="flex">
          <div><span class="bolder">应用key：</span>{{ item.key }}</div>
          <div>
            <span class="bolder">应用密钥：</span>
            {{
              item.secretShow
                ? item.secret
                : '**************************************************'
            }}
            <i
              :class="['fa', item.secretShow ? 'fa-eye-slash' : 'fa-eye']"
              @click="handelSecrect(item, index)"></i>
          </div>
        </div>
      </div>
    </div>
    <no-data v-else></no-data>
    <bc-dialog
      class="base-dialog bind-dialog"
      width="430px"
      :visible="isShowBindDialog"
      @confirm="handleBindConfirm"
      @cancel="handleBindConfirm"
      title="配置管理">
      <template v-slot:dialogContent>
        <div class="flex flex-nowrap flex-between">
          <span>配置：</span>
          <el-select
            clearable
            v-model="bindValue"
            placeholder="请选择"
            style="width: 68%">
            <el-option
              v-for="item in bindOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
          <span
            class="bind-btn"
            @click="handelAddBind"
            >绑定</span
          >
        </div>
        <div class="title">已绑定配置列表：</div>
        <ul class="bind-item">
          <li
            v-for="(item, index) in appThirdLists"
            :key="index"
            class="flex flex-between">
            <span>{{ item.third_name }}</span>
            <i
              class="el-icon-delete-solid"
              @click="handelDelBind(item.id)"></i>
          </li>
        </ul>
      </template>
    </bc-dialog>
    <bc-dialog
      width="550px"
      :visible="isShowDialog"
      :title="type === 'add' ? '新增应用' : '编辑应用'"
      class="base-dialog"
      @confirm="handleConfirm"
      @cancel="handleCancle">
      <template v-slot:dialogContent>
        <el-form
          ref="editorForm"
          class="editor-form"
          :model="form"
          :rules="rules"
          label-width="100px"
          label-position="right">
          <el-form-item
            prop="name"
            label="名称">
            <el-input
              v-model.trim="form.name"
              placeholder="请输入名称">
            </el-input>
          </el-form-item>
          <el-form-item
            prop="package_name"
            label="包名">
            <el-input
              v-model.trim="form.package_name"
              placeholder="请输入包名">
            </el-input>
          </el-form-item>
          <el-form-item
            prop="generate_type"
            label="id生成倾向">
            <el-radio-group
              v-model="form.generate_type"
              @change="handelChange">
              <el-radio label="1">系统</el-radio>
              <el-radio label="2">传值</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            v-if="form.generate_type === '1'"
            prop="generate_rule"
            label="id生成规则">
            <el-radio-group
              v-model="form.generate_rule"
              @change="handelChange">
              <el-radio label="1">系统固定</el-radio>
              <el-radio label="2">系统随机</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            prop="pay_notify_url"
            label="支付回调地址">
            <el-input
              v-model.trim="form.pay_notify_url"
              placeholder="请输入您的服务器的Webhook地址">
            </el-input>
          </el-form-item>
          <el-form-item
            prop="refund_notify_url"
            label="退款回调地址">
            <el-input
              v-model.trim="form.refund_notify_url"
              placeholder="请输入退款回调地址">
            </el-input>
            <p class="marks">注释：仅支持国内微信、支付宝</p>
          </el-form-item>
          <el-form-item
            prop="adjust_mode"
            label="adjust模式">
            <el-radio-group
              v-model="form.adjust_mode"
              @change="handelChange">
              <el-radio label="0">沙盒</el-radio>
              <el-radio label="1">生产</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            prop="adjust_status"
            label="adjust状态">
            <el-radio-group
              v-model="form.adjust_status"
              @change="handelChange">
              <el-radio label="0">禁用</el-radio>
              <el-radio label="1">启用</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="帐号模式"
            class="merge_uid">
            <div class="el-icon-warning-outline"></div>
            <div class="warning">
              合并帐号：同一设备上登录的第三方（新）、游客合并成一个帐号<br />
              区分游客：同一设备上登录的第三方（新），合并成一个帐号，游客单独一个帐号<br />
              独立帐号：游客、第三方(新)，分别生成独立的帐号<br />
            </div>
            <el-select
              clearable
              v-model="form.is_merge_uid"
              placeholder="请选择"
              style="width: 100%">
              <el-option
                v-for="item in mergeOptions"
                :key="item.value"
                :label="item.name"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </template>
    </bc-dialog>
  </div>
</template>

<script>
import {
  getAppList,
  appAdd,
  appEdit,
  getThirdsList,
  appThirdAdd,
  appThirdList,
  delAppThird,
} from '@/api/application';
import NoData from '@/components/Nodata';

export default {
  name: 'application',
  components: {
    NoData,
  },
  data() {
    return {
      lists: [],
      isShowDialog: false,
      pageLoading: false,
      form: {
        name: '',
        pay_notify_url: '',
        is_merge_uid: true,
      },
      rules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        package_name: [
          { required: true, message: '请输入包名', trigger: 'blur' },
        ],
        // pay_notify_url: [
        //   { required: true, message: '请输入服务器的Webhook地址', trigger: 'blur' },
        // ],
      },
      type: 'add',
      isShowBindDialog: false,
      bindOptions: [],
      bindValue: '',
      appId: 0,
      appThirdLists: [],
      mergeOptions: [
        {
          name: '合并帐号(按设备)',
          value: 1,
        },
        {
          name: '区分游客(按设备)',
          value: 0,
        },
        {
          name: '独立帐号(按渠道)',
          value: 2,
        },
      ],
    };
  },
  methods: {
    handleConfirm() {
      this.$refs['editorForm'].validate((valid) => {
        if (valid) {
          this.isShowDialog = false;
          this.type === 'add' ? this.addData() : this.editData();
        } else {
          return false;
        }
      });
    },
    handleCancle() {
      this.$refs['editorForm'].resetFields();
      this.isShowDialog = false;
    },
    handelSecrect(item, index) {
      this.$set(this.lists[index], 'secretShow', !item.secretShow);
    },
    handelAdd() {
      this.isShowDialog = true;
      this.type = 'add';
      this.form.name = '';
      this.form.package_name = '';
      this.form.pay_notify_url = '';
      this.form.refund_notify_url = '';
      this.form.is_merge_uid = 1;
      this.form.adjust_mode = '0';
      this.form.adjust_status = '0';
      this.form.generate_type = '1';
      this.form.generate_rule = '1';
    },
    handelChange() {
      this.$forceUpdate();
    },
    handelEdit(item) {
      this.type = 'edit';
      this.isShowDialog = true;
      this.form = JSON.parse(JSON.stringify(item));
      this.form.adjust_mode = item.adjust_config.mode;
      this.form.adjust_status = item.adjust_config.status;
      this.form.generate_type = item.generate_type.toString();
      this.form.generate_rule = item.generate_rule.toString();
    },
    addData() {
      appAdd(this.form).then((res) => {
        if (res.code === 200) {
          this.$message.success(res.message);
          this.loadData();
        }
      });
    },
    editData() {
      appEdit(this.form).then((res) => {
        if (res.code === 200) {
          this.$message.success(res.message);
          this.loadData();
        }
      });
    },
    loadData() {
      this.pageLoading = true;
      getAppList({
        page: 1,
        limit: 10000,
      }).then((res) => {
        res.data.list.forEach((i) => {
          i.secretShow = false;
        });
        this.lists = res.data.list;
        this.pageLoading = false;
      });
    },
    getOption() {
      getThirdsList().then((res) => {
        this.bindOptions = res.data.list;
      });
    },
    toBind() {
      this.$router.push('/console/third');
    },
    showMessage() {
      const h = this.$createElement;
      return this.$message({
        type: 'warning',
        message: h('p', null, [
          h('span', null, '暂无可绑定配置! '),
          h(
            'span',
            {
              style: 'color:#5b03e4;cursor: pointer',
              on: {
                click: this.toBind,
              },
            },
            '去新增'
          ),
        ]),
      });
    },
    handelBindShow(item) {
      this.appId = item.id;
      this.bindValue = '';
      this.isShowBindDialog = true;
      this.getAppThirdList(item.id);
    },
    handleBindConfirm() {
      this.isShowBindDialog = false;
    },
    handelAddBind() {
      let params = {
        app_id: this.appId,
        third_id: this.bindValue,
      };
      appThirdAdd(params).then((res) => {
        if (res.code === 200) {
          this.$message.success(res.message);
          this.getAppThirdList(this.appId);
        }
      });
    },
    handelDelBind(id) {
      delAppThird({
        id: id,
      }).then((res) => {
        if (res.code === 200) {
          this.$message.success(res.message);
          this.getAppThirdList(this.appId);
        }
      });
    },
    getAppThirdList(appid) {
      let params = {
        app_id: appid,
      };
      appThirdList(params).then((res) => {
        if (res.code === 200) {
          this.appThirdLists = res.data.list;
        }
      });
    },
  },
  mounted() {
    this.getOption();
    this.loadData();
  },
};
</script>

<style lang="scss" scoped>
.application {
  margin: 0 auto;
  font-size: 14px;
  // background: url(~@/assets/images/banner-left.png) no-repeat;
  padding: 30px;
  height: calc(100vh - 110px);
  overflow-y: auto;
  .add {
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #5b03e4;
    color: #fff;
    text-align: center;
    width: 100px;
    cursor: pointer;
    float: right;
  }
  .lists {
    width: 100%;
    overflow: hidden;
    div {
      line-height: 35px;
    }
  }
  .summary {
    background: #fbf9fd;
    padding: 5px;
    border-radius: 15px;
    margin: 10px 0;
    .flex {
      div {
        margin: 0 !important;
      }
    }
    .summary-item {
      text-align: center;
    }
    span {
      padding-left: 50px;
    }
    .down {
      color: #f56c6c;
    }
    .up {
      color: #67c23a;
    }
    .el-icon-bottom,
    .el-icon-top {
      font-size: 18px;
    }
  }
  .list-item {
    border: 1px solid #f3d7ff;
    border-radius: 15px;
    padding: 30px;
    width: 100%;
    margin: 20px 0;
    background-color: #f9eeff;
    cursor: pointer;
    .long-text {
      overflow-wrap: break-word;
    }
    .list-item-one {
      span {
        padding-right: 60px;
      }
    }
    .flex {
      div {
        flex-basis: 50%;
        margin-right: 40px;
      }
    }
    .name {
      font-weight: bold;
      font-size: 20px;
      width: 250px;
      display: inline-block;
    }
    .primary-color,
    .fa {
      color: #5b03e4;
    }
    .icon-edit {
      color: #5b03e4;
      font-size: 23px;
      float: right;
      i {
        margin-left: 5px;
      }
    }
    .bind-success {
      color: #67c23a;
    }
  }
}
</style>
<style lang="scss">
.bind-dialog {
  .el-dialog__footer {
    display: none;
  }
}
.bind-btn {
  padding: 0 20px;
  background-color: #5b03e4;
  color: #fff;
  font-size: 12px;
  border-radius: 8px;
  height: 25px;
  line-height: 25px;
  display: inline-block;
}
.base-dialog {
  .bind-btn {
    height: 35px;
    line-height: 35px;
    margin-left: 10px;
  }
  .bind-item {
    width: 100%;
    li {
      width: 100%;
    }
    .el-icon-delete-solid {
      cursor: pointer;
      color: #f56c6c;
      font-size: 18px;
    }
  }

  .title {
    font-weight: bold;
    font-size: 14px;
    margin: 20px 0;
  }
  .warning {
    transition: display 0.4s ease-in-out;
    background: #faecdb;
    color: #e6a23c;
    padding: 10px;
    border-radius: 10px;
    font-size: 12px;
    line-height: 20px;
    position: absolute;
    left: -90px;
    bottom: -80px;
    display: none;
  }
  .marks {
    font-size: 12px;
    color: #f56c6c;
  }
  .el-icon-warning-outline {
    color: #e6a23c;
    font-size: 20px;
    position: absolute;
    left: -90px;
    top: 10px;
    cursor: pointer;
  }
  .el-icon-warning-outline:hover + .warning {
    display: block;
  }
  .merge_uid {
    position: relative;
  }
}
</style>
